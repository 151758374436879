import React from "react";
import isEmpty from "../util/isEmpty"
import toHtml from 'string-to-html';

function Resume(props) {
  const {year, position, previousPosition, graduation, university, company, details, technologiesUsed, responsibilites, achievements, platformsUsed } = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation}</h5>
        {!isEmpty(previousPosition) && <h6>Previous Positions : {previousPosition}</h6>}
        <h6 className="mi-resume-company">{company || university}</h6>
        <p>{details}</p>
        {!isEmpty(technologiesUsed) && 
          <div>
            <br/>
            <h6>Technologies Used</h6>
            <p style={{marginLeft: "40px"}}>
              <ul><li>{technologiesUsed}</li></ul>
            </p>
          </div>
        }{!isEmpty(platformsUsed) && 
        <div>
          <br/>
          <h6>Platforms Used</h6>
          <p style={{marginLeft: "40px"}}>
            {!Array.isArray(platformsUsed) && <ul><li>{platformsUsed}</li></ul>}
            {Array.isArray(platformsUsed) && <ul>
              {platformsUsed.map(platformUsed => (
                <li>{platformUsed.text}</li>
              ))}
            </ul>}
          </p>
        </div>
      }
        {!isEmpty(responsibilites) && 
          <div>
            <br/>
            <h6>Responsibilties</h6>
            <p style={{marginLeft: "40px"}}>
              <ul>
                {responsibilites.map(resposibility => (
                    <li>{resposibility.text}</li>
                ))}
              </ul>
            </p>
          </div>
        }
        {!isEmpty(achievements) && 
          <div>
            <br/>
            <h6>Achievements</h6>
            <p style={{marginLeft: "40px"}}>
              <ul>
                {achievements.map(achievement => (
                    <li>{achievement.text}</li>
                ))}
              </ul>
            </p>
          </div>
        }
      </div>
    </div>
  );
};

export default Resume;
