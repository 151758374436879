import Mock from "../mock";
import React, { Component } from 'react';

const database = {
  information: {
    name: 'Shane',
    fullName: 'Shane Peiris',
    aboutContent: "Specialised in the MERN stack development for the past 5 years with a total industrial experience of over 10 years, including 6 years of experience working with AWS services and AWS Support Specialists. My passion has always been around writing clean code and pixel perfect design. I am always up for a new challenge and never say NO to learn something new.",
    age: 30,
    phone: '',
    nationality: 'Sri Lankan',
    language: 'English, Sinhalese',
    email: 'shane@peiris.email',
    address: '',
    freelanceStatus: 'Available',
    fulltimeJobStatus: 'In the lookout',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/shanepeiris1993/',
      dribbble: '',
      github: 'https://github.com/shane-peiris'
    },
    brandImage: '/images/shane-300x300.jpg',
    aboutImage: '/images/shane-750x565.jpg',
    aboutImageLg: '/images/shane-750x565.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Web Development",
      icon: 'code',
      details: "End to end development of websites from requirments gathering to hosting services."
    },
    {
      title: "CI/CD Pipelines",
      icon: 'construction',
      details: "Deliver apps to customers by introducing automation into the stages of app development."
    },
    {
      title: "AWS Services",
      icon: 'cloud',
      fullLength: true,
      details: "Ensure the right implementation of an AWS infrastructure"
    },
    {
      title: "E-Commerce Platforms",
      icon: 'coin',
      details: "Web development and integration to payment platforms such as Stripe, Pin Payments, PayPal etc."
    },
    {
      title: "SaaS Development",
      icon: 'layers',
      details: "Hosted applications to suit your needs and services."
    },
    {
      title: "API Integrations",
      icon: 'cloudnetwork',
      details: "Integrations of any third party REST API's to new web platform or any existing services."
    },
    {
      title: "Project Management",
      icon: 'grow',
      details: "Manage your project requirment gatherting to timelines to project delivery."
    },
    {
      title: "Web Design",
      icon: 'color-pallet',
      details: "Custome web designs to suit your crazy and imaginery needs."
    },
    {
      title: "Anything Digital?",
      icon: 'world',
      details: "Let's catchup online to discuss your digital needs."
    },
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "ReactJS",
      value: 85
    },
    {
      title: "NodeJS",
      value: 80
    },
    {
      title: "ExpressJS",
      value: 75
    },
    {
      title: "AWS",
      value: 80
    },
    {
      title: "Javascript",
      value: 80
    },
    {
      title: "HTML5",
      value: 90
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "jQuery",
      value: 80
    },
    {
      title: "MySQL",
      value: 80
    },
    {
      title: "MongoDB",
      value: 60
    },
    {
      title: "Illustrator",
      value: 75
    },
    {
      title: "Photoshop",
      value: 80
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Voucherstore Web App",
      subtitle: "Voucherstore is a leading forum of on-line shoppers with the best and hottest offers from Australia’s leading retailers. Designed using React JS in the frontend and Express JS in the backend.",
      imageUrl: "/images/portfolio/vs-thumb.png",
      largeImageUrl: [
        "/images/portfolio/vs-large-1.png",
        "/images/portfolio/vs-large-2.png",
        "/images/portfolio/vs-large-3.png",
        "/images/portfolio/vs-large-4.png",
        "/images/portfolio/vs-large-5.png",
        "/images/portfolio/vs-large-6.png",
        "/images/portfolio/vs-large-7.png",
        "/images/portfolio/vs-large-8.png"
      ],
      url: 'https://voucherstore.com.au'
    },
    {
      id: 2,
      title: "Sendeze iOS Mobile App",
      subtitle: "SendEze is an online delivery service revolutionising the traditional courier service. Designed using React Native with the Expo SDK.",
      imageUrl: "/images/portfolio/sendeze-ios-thumb.png",
      largeImageUrl: [
        "/images/portfolio/sendeze-ios-large-1.png",
        "/images/portfolio/sendeze-ios-large-2.png",
        "/images/portfolio/sendeze-ios-large-3.png",
        "/images/portfolio/sendeze-ios-large-4.png",
        "/images/portfolio/sendeze-ios-large-5.png",
        "/images/portfolio/sendeze-ios-large-6.png",
        "/images/portfolio/sendeze-ios-large-7.png",
      ],
      url: 'https://apps.apple.com/au/app/sendeze/id1483890574'
    },
    {
      id: 3,
      title: "CPD - Holmeslist",
      subtitle: "Content Platform Delivery designed to host video and audio content for Barristers and Solicitors to earn points. Designed using wordpress under 2 weeks.",
      imageUrl: "/images/portfolio/cpd-thumb.png",
      largeImageUrl: [
        "/images/portfolio/cpd-large-1.png",
        "/images/portfolio/cpd-large-2.png",
        "/images/portfolio/cpd-large-3.png",
        "/images/portfolio/cpd-large-4.png",
        "/images/portfolio/cpd-large-5.png",
      ],
      url: 'https://cpd.holmeslist.com.au/'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2019 Aug. - Present",
        position: "Senior Software Engineer",
        previousPosition: "Developer Programmer",
        company: "Electricity Wizard Pty. Ltd (Docklands, Australia)",
        details: `A leading online Energy Broker who has been in the industry for the past 11 years. Over 1 million comparison have been done online using the online platform found at https://electricitywizard.com.au.`,
        technologiesUsed: "ReactJS, NodeJS, ExpressJS, VueJS, NextJS, HTML5, CSS3, MySQL, MongoDB",
        platformsUsed: [
          {
            id: 1,
            text: 'AWS, Vultr, Linode, Webmin, Virtualmin',
          },
          {
            id: 2,
            text: 'AWS Services - Code Pipeline, Amplify, Elastic Beanstalk, EC2, RDS, VPC',
          },
          {
            id: 3,
            text: 'GitHub',
          },
          {
            id: 4,
            text: 'Google Services (Tag Manager, AdWords), Facebook Business Manager',
          },
          {
            id: 5,
            text: 'JIRA, Zoho, Asana, Trello, Slack',
          },
        ],
        responsibilites: [
          {
            id: 1,
            text: 'Manage and maintain all internal and external websites within the company',
          },
          {
            id: 2,
            text: 'Build and maintain new content management system for all internal and external system',
          },
          {
            id: 3,
            text: 'Third party API integrations within systems',
          },
          {
            id: 4,
            text: 'Manage and maintain all online hosted servers and online services',
          },
        ],
        achievements: [
          {
            id: 1,
            text: 'Successfully integrated retailer API to process sales instead of the standard SFTP delivery of sales via CSV.',
          },
          {
            id: 2,
            text: 'Implement work from home infrastructure with the help of AWS Services such as Workspaces.',
          },
          {
            id: 3,
            text: 'Migrate the entire office infrastructure to the AWS cloud with the help of AWS Services such as VPC, VPN, Site to Site Networks, Managed AD, EC2 and more.',
          },
          {
            id: 3,
            text: 'Automation of sales, activity and backup reports.',
          },
        ],
      },
      {
        id: 2,
        year: "2017 Aug. - 2019 Aug.",
        position: "Software Engineer",
        company: "Ethical Prospecting Pty. Ltd. (Essendon, Australia)",
        details: "An Energy Broker who has been in the industry for the past 5 years. They work with retailer clients such as Alinta Energy, Simply Energy, ActewAGL, OVO Energy, Next Business Energy, SUMO etc.",
        technologiesUsed: "PHP, jQuery, HTML, CSS, MySQL",
        platformsUsed: [
          {
            id: 1,
            text: 'AWS, Vultr',
          },
          {
            id: 2,
            text: 'AWS Services - EC2, RDS',
          },
          {
            id: 3,
            text: 'GitHub',
          },
          {
            id: 4,
            text: 'Google Services (Tag Manager, AdWords), Facebook Business Manager',
          },
          {
            id: 5,
            text: 'Trello, Slack',
          },
        ],
        responsibilites: [
          {
            id: 1,
            text: 'Manage, design and develop of company website',
          },
          {
            id: 2,
            text: 'Build, Manage and develop CRM (Pipedrive and custom intranet)',
          },
          {
            id: 3,
            text: 'Automation of day today activities, such as Sales processing to all clients',
          },
          {
            id: 4,
            text: 'Reporting, alongside developing of reporting tools',
          },
          {
            id: 5,
            text: 'Manage and provide IT support',
          },
          {
            id: 6,
            text: 'Lead the Digital Transformation of the business',
          }
        ],
        achievements: [
          {
            id: 1,
            text: 'Automation of all sales processing, cutting down 3 hours of daily work to just 15 minutes.',
          }
        ],
      },
      {
        id: 3,
        year: "2017 Sept.  - 2017 Sept.",
        position: "PHP/Web Developer (Contract)",
        company: "Fatfish (St.Kilda, Australia)",
        details: "A full service creative, digital and design studio that’s all about crafting bespoke solutions for clients.",
        technologiesUsed: "PHP (WordPress development)",
        platformsUsed: "AWS S3 Bucket",
        responsibilites: [
          {
            id: 1,
            text: 'OAuth login and data retrieval Web API Service Integration for existing website.',
          },
        ],
        achievements: [
          {
            id: 1,
            text: 'Web API Service integrated within 2 days',
          },
        ],
      },
      {
        id: 4,
        year: "2015 Sept. - 2017 Jul.",
        position: "Software Engineer / Head of Digital",
        company: "BBDO Lanka Ptv. Ltd. (Sri Lanka)",
        details: `BBDO Lanka is an advertising/digital agency which is part of BBDO Worldwide. BBDO Lanka handles major brands varying from Hotels to FMCG brands`,
        technologiesUsed: "HTML, CSS, jQuery, PHP",
        platformsUsed: "Tools for Digital Advertising such as Facebook, Instagram, Twitter, Google, Bannersnack, AdRoll and monitoring tools such as Cyfe, Iconsquare, Fanpagekarma, mentions",
        responsibilites: [
          {
            id: 1,
            text: 'Desktop/Web Design and Development',
          },
          {
            id: 2,
            text: 'Mobile App Design and Development (Android)',
          },
          {
            id: 3,
            text: 'Live Streaming Events',
          },
          {
            id: 4,
            text: 'Uploading of all developments to the Live environment',
          },
          {
            id: 5,
            text: 'Client co-ordination',
          },
          {
            id: 6,
            text: 'Managing projects, gathering requirements and assigning tasks to team members',
          },
          {
            id: 7,
            text: 'Social Media Strategy/Content Development and Execution',
          },
          {
            id: 8,
            text: 'Monitoring and Budgeting for campaigns and daily advertisements',
          }
        ],
        achievements: [
          {
            id: 1,
            text: 'Sri Lanka’s first Facebook Messenger Bot',
          },
          {
            id: 2,
            text: 'Ran the first profile video campaign in Sri Lanka',
          },
          {
            id: 3,
            text: 'Revived an award for the most effective and innovative brand of year 2015 to an FMCG Milk brand',
          }
        ],
      },
      {
        id: 5,
        year: "2015 Jan. - 2015 Sept.",
        position: ".Net Developer",
        company: "Davton UK (Sri Lanka)",
        details: "Davton UK is a Software Development company based in the United Kingdom which started off a development office in Sri Lanka. Mainly focused on their core product which is known as co-operate contacts.",
        technologiesUsed: "MsSQL, C#, MVC, .Net Framework, Entity Framework, HTML, HTML5, CSS3, Javascript/JQuery, OAuth Services",
        platformsUsed: "AWS, Slack, Trello",
        responsibilites: [
          {
            id: 1,
            text: 'Web Design and Development',
          },
          {
            id: 2,
            text: 'Outlook Web App Design and Development',
          },
          {
            id: 3,
            text: 'Managing of all repositories, branches and merging of code on GitHub',
          },
          {
            id: 4,
            text: 'Uploading of all developments to the Staging and Live environments',
          },
          {
            id: 5,
            text: 'Documentation of project details (Technical and User Manuals)',
          }
        ],
        achievements: [
          {
            id: 1,
            text: 'UI Fully revamped on a self-approach and brought a lot of positive user and management feedback',
          },
          {
            id: 2,
            text: 'Developed the first Outlook web app for the company',
          }
        ],
      },
      {
        id: 6,
        year: "2013 Apr. - 2015 Jan.",
        position: "Software Engineer",
        company: "BBDO Lanka Ptv. Ltd. (Sri Lanka)",
        details: "BBDO Lanka is an advertising/digital agency which is part of BBDO Worldwide. BBDO Lanka handles major brands varying from Hotels to FMCG brands",
        technologiesUsed: "",
        responsibilites: [
          {
            id: 1,
            text: 'Desktop/Web Design and Development',
          },
          {
            id: 2,
            text: 'Mobile App Design and Development (Android)',
          },
          {
            id: 3,
            text: 'Live Streaming Events',
          },
          {
            id: 4,
            text: 'Uploading of all developments to the Live environment',
          }
        ],
        achievements: [
          {
            id: 1,
            text: 'Developed the first golfing app in Sri Lankan',
          },
          {
            id: 2,
            text: 'Developed a live streaming with multiple switch channels',
          }
        ],
      },
      {
        id: 7,
        year: "2013 Feb. - 2013 Apr.",
        position: "Associate Software Engineer",
        company: "Rezgateway Ptv. Ltd. (Sri Lanka)",
        details: "Rezgateway is a booking search engine provider which caters to customers from all over the world by providing, flight search engines, hotel search engines etc.",
        technologiesUsed: "C#, XML",
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2017 - 2019",
        graduation: "MSc. in Information Technology (Professional Computing)",
        university: "Swinburne University of Technology, Hawthorn, Victoria, Australia",
        details: ""
      },
      {
        id: 2,
        year: "2012 - 2014",
        graduation: "BSc. in Computer Science",
        university: "Offered by University College of Dublin, Ireland. Conducted at National School of Business Management, Sri Lanka",
        details: "Obtained a First Class Honours (GPA 3.83)"
      },
      {
        id: 3,
        year: "2011 - 2012",
        graduation: "Higher Diploma in Computer Based Information Systems",
        university: "National Institute of Business Management, Sri Lanka",
        details: "Obtained a First Class Honors"
      },
      {
        id: 4,
        year: "2010 - 2011",
        graduation: "Diploma in Computer Systems Design",
        university: "National Institute of Business Management, Sri Lanka",
        details: "Obtained a Distinction with a Full Scholarship for the Higher Diploma and a Gold Medal for being the batchtop-islandwide"
      },
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: [''],
    emailAddress: ['shane@peiris.email'],
    address: ""
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
