import React, { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import PortfoliosView from "../components/PortfoliosView";

function Portfolios() {
  const [portfolios, setPortfoios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfoliosPerPage] = useState(9);

  useEffect(() => {
    axios.get('/api/portfolios')
      .then( response => {
        setPortfoios(response.data);
      })
  }, [portfolios]);

  const indexOfLastPortfolios = currentPage * portfoliosPerPage;
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
  const currentPortfolios = portfolios.slice(indexOfFirstPortfolios, indexOfLastPortfolios);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  }

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Portfolio" />
          <div className="row">
            <div className="col-lg-12">
              <div className="mi-about-content">
                {/* <h1>
                  Hi, I am <span className="color-theme">{information.name}</span>
                </h1> */}
                <p>Most of the projects that I have worked on are for internal use within the business, but below are few of the projects that are available to the public.</p>
              </div>
            </div>
          </div>
          {/* <Sectiontitle title="Portfolios" /> */}
          <PortfoliosView portfolios={currentPortfolios}/>
          {!(portfolios.length > portfoliosPerPage) ? null : (
            <Pagination 
              className="mt-50"
              itemsPerPage={portfoliosPerPage}
              totalItems={portfolios.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Portfolios;
